/* istanbul ignore file */

import { ApplyCouponResponse, BillingPeriodicity } from '@yoimo/interfaces';
import { firstValueFrom, switchMap } from 'rxjs';

import { Functions } from '@angular/fire/functions';
import { Injectable } from '@angular/core';
import { ScopeService } from '@core/services';
import { applyCoupon } from '@yoimo/client-sdk/subscriptions';

@Injectable({ providedIn: 'root' })
export class CouponService {
  constructor(private fs: Functions, private scopeService: ScopeService) {}

  applyCoupon(
    couponCode: string,
    planId: string,
    periodicity: BillingPeriodicity,
    currency: string
  ): Promise<ApplyCouponResponse> {
    return firstValueFrom(
      this.scopeService
        .listenToChannel()
        .pipe(
          switchMap((channel) =>
            applyCoupon(
              this.fs,
              couponCode,
              channel.docId,
              planId,
              periodicity,
              currency
            )
          )
        )
    );
  }
}
