<section class="m-t-48">
  <header>
    <h3 class="heading-sm">{{ title }}</h3>
    <hr class="m-t-12 m-b-20" />
  </header>

  <div #stripeElements class="md:w-75 m-x-auto"></div>

  <footer *ngIf="state; else loader">
    <yo-button
      type="submit"
      role="primary"
      size="medium"
      class="flex flex-justify-center m-t-16"
      [loading]="state === 'loading'"
      (click)="onSubmit.emit()"
      i18n="@@capturePaymentConfirmButtonLabel"
      >Confirm</yo-button
    >
  </footer>
</section>

<ng-template #loader>
  <p class="flex flex-column flex-align-center gap-8">
    <yo-icon icon="loader" size="xl"></yo-icon>
    <span i18n="@@capturePaymentPreparingPaymentText"
      >Preparing a secure payment form...</span
    >
  </p>
</ng-template>
