<div class="flex flex-align-start gap-8 flex-column sm:flex-row sm:gap-12">
  <yo-button
    role="link"
    [hidden]="displayCouponInput"
    (click)="displayCouponInput = true"
  >
    <yo-icon icon="coupon"></yo-icon>
    <span i18n="@@iHaveACouponLabel">I have a coupon</span>
  </yo-button>

  <ng-container *ngIf="displayCouponInput">
    <yo-text-field
      [label]="couponLabel"
      [clearable]="true"
      placeholder="Add coupon code"
      [control]="coupon"
      (keyup.enter)="applyCoupon(coupon.value)"
      [readonly]="isCouponApplied"
      [customValidationRulesAndMessages]="couponInvalidRuleMessage"
      class="block xs:w-100"
    ></yo-text-field>
    <yo-button
      *ngIf="!isCouponApplied"
      type="submit"
      role="tertiary"
      [loading]="state === 'loading'"
      (click)="applyCoupon(coupon.value)"
      i18n="@@applyCouponButtonLabel"
      class="sm:p-t-24"
    >
      Apply
    </yo-button>
    <div *ngIf="isCouponApplied" class="flex flex-column sm:m-t-32 text-sm">
      <span class="text-secondary" i18n="@@couponAppliedLabel"
        >Coupon&nbsp;applied</span
      >
      <a (click)="cancel()" class="text-link" i18n="@@couponClearLabel"
        >Clear</a
      >
    </div>
  </ng-container>
</div>
