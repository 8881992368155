<yo-card *ngIf="labels">
  <section class="flex flex-justify-between flex-align-start gap-16">
    <yo-icon
      *ngIf="labels.icon"
      [icon]="labels.icon"
      class="block m-r-16"
    ></yo-icon>
    <div class="flex-col text-md-spacious">
      <strong>{{ labels.heading }}</strong>
      <p>{{ labels.description }}</p>
    </div>
    <yo-switch
      [control]="control"
      (onToggle)="onToggle.emit($event)"
    ></yo-switch>
  </section>
</yo-card>
