import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService, UIState } from '@yoimo/joymo-ui';

import { BillingPeriodicity } from '@yoimo/interfaces';
import { CouponService } from '../../../core/services/coupon.service';
import { FormControl } from '@angular/forms';
import { PlanWithDocId } from '@yoimo/client-sdk/subscriptions';

@Component({
  selector: 'joymo-ticket-coupon[control]',
  templateUrl: './ticket-coupon.component.html',
  styleUrls: ['./ticket-coupon.component.scss'],
})
export class TicketCouponComponent {
  @Input() plan?: PlanWithDocId;
  @Input() periodicity?: BillingPeriodicity;
  @Input() control?: FormControl<string>;

  @Output() onDiscountedPrice = new EventEmitter<number | undefined>();

  // Used to avoid firing unnecessary changes to control in the parent
  readonly coupon = new FormControl<string>('');

  displayCouponInput = false;
  isCouponApplied = false;
  state: UIState = 'idle';
  readonly couponInvalidRuleMessage = {
    invalidCoupon: $localize`:@@couponInvalidMessage:Coupon is invalid`,
  };
  readonly couponLabel = $localize`:@@couponLabel:Coupon`;

  constructor(
    private couponService: CouponService,
    private toastService: ToastService
  ) {}

  async applyCoupon(couponCode: string | null): Promise<void> {
    if (!couponCode) {
      return;
    }

    if (!this.plan || !this.periodicity) {
      throw new Error('Plan data and periodicity are required');
    }

    this.state = 'loading';
    const { currency } = this.plan.priceAlternatives[0];
    const response = await this.couponService.applyCoupon(
      couponCode,
      this.plan.docId,
      this.periodicity,
      currency
    );

    if (!response.success) {
      this.state = 'error';
      this.coupon?.setErrors({ invalidCoupon: true });
      this.toastService.open(`This coupon is invalid`, { type: 'error' });
      return;
    }

    this.onDiscountedPrice.emit(response.totalDue);
    this.control?.setValue(couponCode);
    this.updateAppliedStatus(true);
  }

  cancel(): void {
    this.updateAppliedStatus(false);
    this.onDiscountedPrice.emit(undefined);
    this.coupon.reset();
    this.control?.reset();
  }

  private updateAppliedStatus(isApplied: boolean): void {
    if (!isApplied) {
      this.isCouponApplied = false;
      return;
    }
    this.toastService.open('Coupon applied successfully', { type: 'success' });
    this.isCouponApplied = true;
    this.state = 'ready';
  }
}
