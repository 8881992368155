/* istanbul ignore file */

import {
  AdditionalPaymentConfig,
  PlanOption,
  TicketOption,
} from '../ticket-options-types';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EMPTY, Observable, catchError, map, take } from 'rxjs';

import { BillingPeriodicity } from '@yoimo/interfaces';
import { Functions } from '@angular/fire/functions';
import { ToastService } from '@yoimo/joymo-ui';
import { buySubscription } from '@yoimo/client-sdk/subscriptions';
import { buyTicket } from '@yoimo/client-sdk/events';
import { logger } from '@core/services';

@Component({
  selector: 'joymo-payment-handler',
  templateUrl: './payment-handler.component.html',
  styleUrls: ['./payment-handler.component.scss'],
})
export class PaymentHandlerComponent implements OnInit {
  protected paymentProcessStep:
    | 'SETUP'
    | 'WAIT_USER_INPUT'
    | 'PROCESSING'
    | 'SUCCESS'
    | 'FAILURE' = 'SETUP';

  @Input() additionalConfig: AdditionalPaymentConfig = {};
  @Input() channelId?: string;
  @Input() confirmUrl?: string;
  @Input() eventId?: string;
  @Input() option?: TicketOption | PlanOption;
  @Input() supportEmail?: string | null;
  @Input() videoId?: string;
  @Output() onActionResult = new EventEmitter<'success' | 'failure'>();
  readonly title = $localize`:@@paymentHandlerTitle:Payment details`;

  constructor(private ff: Functions, private toastService: ToastService) {}

  ngOnInit(): void {
    if (!this.channelId) {
      throw new TypeError('No channel ID specified for payment');
    }
    if (!this.option) {
      throw new TypeError('No option selected for payment');
    }
    this.paymentProcessStep = 'WAIT_USER_INPUT';
  }

  buyOption(paymentMethodId: string, option: TicketOption | PlanOption): void {
    this.finalizePayment$(paymentMethodId, option)
      .pipe(
        catchError((e) => {
          logger.logCriticalError(e, 'PAYMENTS');
          this.onActionResult.next('failure');
          this.toastService.open(
            $localize`:@@paymentHandlerErrorToastMessage:An error has occurred`,
            { type: 'error' }
          );
          return EMPTY;
        }),
        take(1)
      )
      .subscribe((_resultOptionId) => {
        this.paymentProcessStep = 'SUCCESS';
        this.onActionResult.next('success');
        this.toastService.open(
          $localize`:@@paymentHandlerSuccessToastMessage:Payment successful`,
          { type: 'success' }
        );
      });
  }

  private finalizePayment$(
    paymentMethodId: string,
    selectedOption: TicketOption | PlanOption
  ): Observable<string> {
    if (selectedOption.type === 'PLAN') {
      if (!this.additionalConfig.periodicity)
        throw new TypeError('No periodicity provided');
      return this.buySubscription$(
        selectedOption,
        this.additionalConfig.periodicity,
        paymentMethodId,
        this.additionalConfig.coupon,
        this.additionalConfig.useInstallments
      );
    }
    return this.buySingleTicket$(
      selectedOption,
      paymentMethodId,
      this.additionalConfig.coupon
    );
  }

  /** @returns SoldTicket ID */
  private buySingleTicket$(
    option: TicketOption,
    paymentMethodId: string,
    _coupon?: string
  ): Observable<string> {
    return buyTicket(
      this.ff,
      this.channelId!,
      this.eventId!,
      option.ticketData.id,
      paymentMethodId,
      this.videoId,
      option.optionPrice?.currency
    ).pipe(
      map((result) => {
        if (!result.success) {
          throw new Error(result.errorReason);
        }
        return result.ticketEntryId;
      })
    );
  }

  /** @returns Subscription ID */
  private buySubscription$(
    selectedOption: PlanOption,
    selectedPeriodicity: BillingPeriodicity,
    paymentMethodId: string,
    coupon?: string,
    useInstallments?: boolean
  ): Observable<string> {
    return buySubscription(
      this.ff,
      this.channelId!,
      selectedOption.planData.docId,
      selectedPeriodicity,
      paymentMethodId,
      selectedOption.planData.priceAlternatives[0].currency,
      coupon,
      useInstallments
    ).pipe(
      map((result) => {
        if (!result.success) {
          throw new Error(result.error);
        }
        return result.subscriptionId;
      })
    );
  }
}
