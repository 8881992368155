import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  LOCALE_ID,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { getInstallmentsDescription } from '@core/utilities';
import { getRecurringPriceParameters } from '@yoimo/client-sdk/business-logic';

import { BillingPeriodConfiguration } from '@yoimo/interfaces';
import { CardComponent, SwitchComponent, IconModule } from '@yoimo/joymo-ui';

const defaultInstallmentsLabel = $localize`:@@payInInstallmentsHeading:Pay in installments`;

type InstallmentLabels = {
  description?: string;
  heading?: string;
  icon?: string;
};

@Component({
  selector: 'joymo-offer-installments',
  standalone: true,
  imports: [CommonModule, CardComponent, SwitchComponent, IconModule],
  templateUrl: './offer-installments.component.html',
})
export class OfferInstallmentsComponent {
  /** Emits the user choice */
  @Output() onToggle = new EventEmitter<boolean>();
  @Input() currency?: string;
  @Input() set billingPeriodConfig(config: BillingPeriodConfiguration) {
    const { installments } = config;

    if (!installments) throw new Error('MissingInstallmentsConfig');
    if (!this.currency) throw new Error('MissingInstallmentsCurrency');

    this.totalInstallmentsPrice = getRecurringPriceParameters(
      config,
      true
    ).totalOverBindingPeriod;

    this.labels.heading = installments.label || defaultInstallmentsLabel;
    this.labels.description = installments.details;
    this.labels.icon = installments.icon;

    if (!this.labels.description) {
      this.labels.description = getInstallmentsDescription(
        this.localeId,
        config,
        this.currency
      );
    }
  }

  public totalInstallmentsPrice?: number;
  readonly control = new FormControl(false, { nonNullable: true });
  readonly labels: InstallmentLabels = {};

  constructor(@Inject(LOCALE_ID) private localeId: string) {}
}
