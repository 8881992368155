import {
  Appearance,
  Stripe,
  StripeElements,
  loadStripe,
} from '@stripe/stripe-js';
import {
  BrandingService,
  ButtonModule,
  IconModule,
  ToastService,
  UIState,
  WindowService,
} from '@yoimo/joymo-ui';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  Observable,
  ReplaySubject,
  catchError,
  firstValueFrom,
  from,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';

import { CommonModule } from '@angular/common';
import { Functions } from '@angular/fire/functions';
import { logger, ScopeService } from '@core/services';
import { createSetupIntent } from '@yoimo/client-sdk/subscriptions';

type StripeConfig = {
  stripe: Stripe;
  elements: StripeElements;
};

type SetupIntent = {
  publicKey: string;
  clientSecret: string;
};

@Component({
  selector: 'joymo-capture-payment-method',
  standalone: true,
  templateUrl: './capture-payment-method.component.html',
  imports: [
    ButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
  ],
})
export class CapturePaymentMethodComponent implements OnInit {
  @Input() title?: string;
  @Output() onPaymentMethodIdCaptured = new EventEmitter<string>();

  @ViewChild('stripeElements', { static: false })
  private readonly stripeContainer?: ElementRef;
  private readonly _setupIntent$ = new ReplaySubject<SetupIntent>(1);

  readonly stripeConfig$: Observable<StripeConfig>;
  readonly onSubmit = new EventEmitter<void>();
  /**
   * Current input state; starts as undefined to wait until Stripe is loaded
   */
  state?: UIState = undefined;

  constructor(
    private ff: Functions,
    private brandingService: BrandingService,
    private windowService: WindowService,
    private toastService: ToastService,
    private scopeService: ScopeService
  ) {
    this.stripeConfig$ = this._setupIntent$
      .asObservable()
      .pipe(switchMap(this.getStripeConfig$.bind(this)));
  }

  ngOnInit(): void {
    this.getSetupIntent();

    this.onSubmit
      .pipe(
        withLatestFrom(this.stripeConfig$),
        switchMap(([_, state]) => this.getPaymentMethodId$(state))
      )
      .subscribe((paymentMethodId) => {
        if (!paymentMethodId) {
          this.state = 'error';
          this.getSetupIntent();
          return;
        }
        this.onPaymentMethodIdCaptured.emit(paymentMethodId);
      });
  }

  /**
   * Fetch a new SetupIntent. Can be observed through `setupIntent$`
   *
   * @remarks SetupIntents are single-use, so this method can be accessed by
   * parent components in case a payment method is captured successfully
   * but an error ocurrs further down the line
   */
  async getSetupIntent(): Promise<void> {
    const response = await firstValueFrom(
      createSetupIntent(this.ff, this.scopeService.getChannel().docId)
    );
    if (!response.success) {
      logger.logCriticalError(response.errorReason, 'PAYMENTS');
      throw new Error('Failed to setup an intent');
    }
    this._setupIntent$.next(response.intent);
  }

  /**
   * Given an already configured Stripe state, setup and return a payment method.
   */
  /* istanbul ignore next */
  private getPaymentMethodId$(state: StripeConfig): Observable<string | null> {
    this.state = 'loading';

    return from(
      state.stripe.confirmSetup({
        elements: state.elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: this.windowService.document.location.href,
        },
      })
    ).pipe(
      map((result) => {
        if (result.error) {
          this.toastService.open(
            result.error.message ||
              $localize`:@@capturePaymentFailedToastMessage:Operation failed`,
            {
              type: 'error',
            }
          );
          throw new Error(result.error.message);
        }

        return result.setupIntent?.payment_method as string;
      }),
      catchError(() => of(null))
    );
  }

  /* istanbul ignore next */
  private getStripeConfig$(intent: SetupIntent): Observable<StripeConfig> {
    return from(loadStripe(intent.publicKey)).pipe(
      map((stripe) => ({ stripe, intent })),
      switchMap((response) => {
        if (!response.stripe) {
          throw new Error('Unable to load stripe');
        }

        const elements = response.stripe?.elements({
          appearance: this.getStripeInputStyle(),
          clientSecret: response.intent.clientSecret,
        });
        const payment = elements.create('payment');
        payment.mount(this.stripeContainer?.nativeElement);
        this.state = 'idle';

        return of({ stripe: response.stripe, elements });
      })
    );
  }

  /* istanbul ignore next */
  private getStripeInputStyle(): Appearance {
    const themeSelect = <T>(onDarkThemeValue: T, onLightThemeValue: T): T =>
      this.brandingService.themeMode === 'dark'
        ? onDarkThemeValue
        : onLightThemeValue;
    const theme = this.brandingService.getTheme();
    const colors = {
      darkgrey: '#000000',
      darkgrey10: '#0000001a',
      darkgrey40: '#00000066',
      darkgrey80: '#000000cc',
      white: '#ffffff',
      white20: '#ffffff33',
      white50: '#ffffff80',
    };

    return {
      theme: 'stripe',
      variables: {
        // Elements supports custom fonts by passing the 'fonts' option to the Elements group
        // Define Proxima font with custom font
        // https://stripe.com/docs/js/appendix/custom_font_source_object
        //fontFamily: {},

        // Can be either '8px' for rounded style or 'none'
        borderRadius: theme.themeStyle === 'rounded' ? '8px' : '0',
        colorBackground: theme.backgroundColor,
        // Dark-grey-80 for light theme, White for any dark theme
        colorText: themeSelect(colors.white, colors.darkgrey80),
        // Dark-grey-40 for light theme, White-50 for dark one
        colorTextPlaceholder: themeSelect(colors.white50, colors.darkgrey40),
        // Although this color is used for cards only, and we don't have any
        // we can still provide this color
        colorPrimary: theme.accentColor,
        // Red for light theme, Red-bright for dark one
        colorDanger: this.brandingService.redColor || '',
        // Dark-grey-40 for light theme, White-50 for dark one
        colorIcon: themeSelect(colors.white50, colors.darkgrey40),
      },

      rules: {
        // Certain properties don't allow RGBA values so they're defaulted to solid colors
        '.Input': {
          borderColor: themeSelect(colors.white20, colors.darkgrey10),
          boxShadow: 'none',
          color: themeSelect(colors.white, colors.darkgrey),
        },
        '.Input::placeholder': {
          color: 'lightgrey',
        },
        '.Input:focus': {
          borderColor: themeSelect(colors.white50, colors.darkgrey40),
          // White for any light theme, Background color for any dark theme
          backgroundColor: 'transparent',
        },
      },
    };
  }
}
