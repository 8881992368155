import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

/**
 * Format value as currency
 * @remarks If value is not a number, it'll be returned as-is
 * @remarks Trims out the decimals if the amount is round (i.e. no ".00" or ",00" at the end)
 */
@Pipe({ name: 'price', standalone: true })
export class PricePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(
    value: any,
    currency: string | undefined,
    decimalPlaces = 2
  ): string | any {
    if (typeof value !== 'number') {
      return value;
    }
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency,
      maximumFractionDigits: decimalPlaces,
    })
      .format(value)
      .replace(/(\.|\,)00/, '');
  }
}
