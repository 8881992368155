<section *ngIf="option" class="m-t-24 md:m-0 text-md">
  <header>
    <div
      class="flex flex-column md:flex-row md:flex-align-center flex-justify-between gap-24"
    >
      <h3 class="heading-sm m-b-12 md:m-b-0">
        {{ option.cardDetails.title }}
      </h3>
      <yo-segmented-control
        *ngIf="!disablePeriodicityToggle && planOptionsPrices.length > 1"
        [control]="form.controls.periodicity"
        [options]="planOptionsPrices"
      >
        <ng-template #optionTemplateRef let-option="option">
          {{ option.label | parsePeriodicity }}
          <span *ngIf="option.discount" class="discount-label">
            {{ option.discount }}
          </span>
        </ng-template>
      </yo-segmented-control>
    </div>
    <hr class="m-t-12 m-b-20" />
  </header>

  <ng-container
    *ngIf="option.type === 'PLAN'; then planInfo; else ticketInfo"
  ></ng-container>

  <div
    class="flex md:none flex-justify-between bg-transparent m-t-24 p-20 p-x-16 rounded-sm"
  >
    <strong i18n="@@paymentTotalDueLabel">To be paid</strong>
    <div class="text-right">
      <ng-container
        *ngTemplateOutlet="
          totalDueTpl;
          context: {
            description: selectedPlanPrice?.description
          }
        "
      ></ng-container>
    </div>
  </div>
</section>

<ng-template #ticketInfo>
  <ng-container *ngIf="ticket">
    <ng-container
      *ngTemplateOutlet="
        genericInfoTpl;
        context: {
          longDesc: ticket.cardDetails.description,
          price: ticket.optionPrice?.price,
          title: ticket.cardDetails.title
        }
      "
    ></ng-container>

    <p *ngIf="!isTicketRedemptionLimited" class="text-md">
      {{ ticket.cardDetails.validityOrExpiryInfo }}
    </p>
    <div
      *ngIf="isTicketRedemptionLimited"
      class="flex flex-column gap-2 m-t-8 md:w-2/3 text-md"
    >
      <strong>{{ ticket.cardDetails.validityOrExpiryInfo }}</strong>
      <p>The counting starts from the first watching.</p>
      <p>You can safely buy the ticket now and watch the stream later.</p>
    </div>
  </ng-container>
</ng-template>

<ng-template #planInfo>
  <ng-container *ngIf="plan && activePeriodicity">
    <joymo-offer-installments
      *ngIf="activePeriodicity.installments && !form.value.coupon && currency"
      (onToggle)="form.controls.useInstallments.setValue($event)"
      [currency]="currency"
      [billingPeriodConfig]="activePeriodicity"
      [hidden]="disableInstallments"
      class="block m-b-20"
    ></joymo-offer-installments>

    <ng-container
      *ngTemplateOutlet="
        genericInfoTpl;
        context: {
          longDesc: plan.planData.descriptionLong,
          periodicity: selectedPlanPrice?.periodicity,
          price: selectedPlanPrice?.price,
          description: selectedPlanPrice?.description,
          title: plan.cardDetails.title,
          trialLabel: selectedPlanPrice?.trialPeriod,
          showInstallmentsDisclaimer: form.value.useInstallments
        }
      "
    ></ng-container>

    <joymo-ticket-coupon
      *ngIf="
        activePeriodicity.allowPromoCodes &&
        !disableCoupons &&
        !form.value.useInstallments
      "
      (onDiscountedPrice)="
        form.controls.useInstallments.reset(); discountedPrice = $event
      "
      [control]="form.controls.coupon"
      [periodicity]="form.value.periodicity"
      [plan]="plan.planData"
      class="block m-t-20"
    ></joymo-ticket-coupon>
  </ng-container>
</ng-template>

<ng-template
  #genericInfoTpl
  let-longDesc="longDesc"
  let-periodicity="periodicity"
  let-price="price"
  let-trialLabel="trialLabel"
  let-description="description"
  let-firstPayment="firstPayment"
  let-showInstallmentsDisclaimer="showInstallmentsDisclaimer"
>
  <div class="flex flex-column md:flex-row flex-justify-between gap-24">
    <div data-restricted-width>
      <p>{{ longDesc }}</p>
      <p [hidden]="!trialLabel || hideTrialLabel" class="m-t-8">
        <strong class="block text-semibold">{{ trialLabel }}</strong>
        <span [hidden]="!firstPayment">{{ firstPayment }}</span>
      </p>
      <p [hidden]="!showInstallmentsDisclaimer" class="m-t-8">
        <strong class="block">{{ billingLabels.installmentsHeading }}</strong>
        <span>{{ billingLabels.installmentsDisclaimer }}</span>
      </p>
    </div>

    <div class="none md:block text-right">
      <ng-container
        *ngTemplateOutlet="
          totalDueTpl;
          context: {
            description: description
          }
        "
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #totalDueTpl let-description="description">
  <strong class="text-lg">{{ toBePaid$ | async | price : currency }}</strong>
  <div *ngIf="activePeriodicity" class="flex flex-column gap-2">
    <p [hidden]="form.value.useInstallments" class="text-sm">
      {{ description }}
    </p>
    <div
      *ngIf="activePeriodicity.installments"
      [hidden]="!form.value.useInstallments"
      class="flex flex-column text-sm"
    >
      <span>
        {{ billingLabels.periodicity[activePeriodicity.installments.period] }}
      </span>
      <span *ngIf="installmentsTpl">
        {{ installmentsTpl.totalInstallmentsPrice | price : currency }} in total
      </span>
    </div>
  </div>
</ng-template>
