import {
  ButtonModule,
  FormModule,
  GridModule,
  IconModule,
  ModalComponent,
  SubscriptionTicketCardComponent,
  TextFieldComponent,
  TicketCardComponent,
} from '@yoimo/joymo-ui';

import { ParsePeriodicityPipe } from '@core/pipes';
import { CancellationsComponent } from '../cancellations/cancellations.component';
import { CapturePaymentMethodComponent } from '../payments/capture-payment-method/capture-payment-method.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { PaymentHandlerComponent } from './payment-handler/payment-handler.component';
import { PricePipe } from '@core/pipes/price.pipe';
import { TicketCouponComponent } from './ticket-coupon/ticket-coupon.component';
import { TicketOptionSelector } from './ticket-option-selector/ticket-option-selector.component';
import { TicketOptionsComponent } from './ticket-options.component';
import { RouterModule } from '@angular/router';
import { OfferInstallmentsComponent } from '../payments/offer-installments/offer-installments.component';
import { InfoBlockComponent } from 'shared-lib';

@NgModule({
  declarations: [
    PaymentHandlerComponent,
    TicketCouponComponent,
    TicketOptionsComponent,
    TicketOptionSelector,
  ],
  imports: [
    ButtonModule,
    CommonModule,
    SubscriptionTicketCardComponent,
    TicketCardComponent,
    GridModule,
    ModalComponent,
    FormModule,
    IconModule,
    FormsModule,
    PricePipe,
    CancellationsComponent,
    TextFieldComponent,
    CapturePaymentMethodComponent,
    RouterModule,
    OfferInstallmentsComponent,
    ParsePeriodicityPipe,
    InfoBlockComponent,
  ],
  exports: [TicketOptionsComponent, TicketOptionSelector],
})
export class TicketOptionsModule {}
